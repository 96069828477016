// Core
import React, {useContext, useEffect, useRef} from 'react';
// Styles
import * as Styles from '../styles/ambassadors.module.scss';
// Contexts
import {LanguageContext} from '../contexts/language';
// import NavContext from '../contexts/navigation';
// Contents
import content from '../contents/ambassadors';
// External
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import ambas from '../images/model.png';
// import bg from '../images/collage.png';

import {Link} from 'gatsby';

gsap.registerPlugin(ScrollTrigger);


const Ambassador= () => {
    // Language
    const lang = useContext(LanguageContext);
    const contents = lang.language ? content.tamil : content.english;

    // Animation
    const h = useRef();
    const p = useRef();
    const ambassador = useRef();
    // useEffect(() => {
    //     gsap.from(h.current,{
    //         scrollTrigger: {
    //             trigger: h.current,
    //             start: "top 90%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         y:30,
    //         opacity: 0
    //     });
    //     gsap.from(p.current,{
    //         scrollTrigger: {
    //             trigger: p.current,
    //             start: "top 90%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         y:30,
    //         opacity: 0
    //     });
        
    // },[])

    const [para, setPara] = React.useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setPara(para => (para+1)%3);
        },3000);
        return () => clearInterval(interval);
    },[]);
    return(
        <section className={Styles.main} id="Ambassador">
            <h1 ref={h}>{contents.h}</h1>
            
            <Link to="/newAmbassador"  id="ambas1"><div className={Styles.container}>
                <p ref={p}>{contents.p[para]}</p>
            </div></Link>
            <div className={Styles.down}>
                <img src={ambas} alt="Ambassador" id="ambas2" ref={ambassador}/>
                
                
            </div>
        </section>
    );
}

export default Ambassador;

/*
<Link to="/newAmbassador" className={Styles.button}>Become an ambassador</Link>
*/
