import React, { Component, createRef } from 'react';
import Slides from '../components/slides';
import * as Styles from '../styles/landing.module.scss';

import bg from '../images/collage.png';
// import hero from '../images/triplet.png';

import i1 from '../images/footer/1.png';
import i2 from '../images/footer/2.png';
import i3 from '../images/footer/3.png';

const imgs = [
    i1,i2,i3
];

class Landing extends Component{
    constructor(props) {
        super(props);
        this.state = {
            img : 0,
            imRef: createRef()
        }
        this.imChanger = this.imChanger.bind(this);
    }

    imChanger(){
        this.setState({img: (this.state.img+1)%3});
    }

    componentDidMount(){
        setInterval(() => {
            this.imChanger();
        },3000);
        const elles = [
            document.getElementById("ambas1"),
            document.getElementById("ambas2"),
            document.getElementById("footer"),
            document.getElementById("totop")
        ];
        window.addEventListener("scroll", () => {
            if(window.pageYOffset > 100){
                elles[3].style.display = "flex";
                elles[3].style.opacity = "1";
                elles[0].style.transform = "translateX(0)";
                elles[0].style.opacity = 1;
                elles[1].style.transform = "translateX(0)";

            }
            else{
                elles[0].style.transform = "translateX(100vw)";
                elles[0].style.opacity = 0;
                elles[1].style.transform = "translateX(100vw)";
                elles[3].style.opacity = "0";
            }
        })
    }

    render(){
        return (
            <section className={Styles.main} id="home"
                style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: `100%`
                }}>
                <Slides/>
                <div className={Styles.hero} ref={this.state.imRef}>
                    <img src={imgs[this.state.img]} alt="Batter house packets"/>
                </div>
            </section>
        );
    }

    
}

export default Landing;