// import React, { useState, useEffect, useRef, createRef } from 'react';
import React, {createRef} from 'react';
import * as Styles from '../styles/slides.module.scss';
// import {gsap, Elastic} from 'gsap';

import s1 from '../images/slides/slide1.png';
import s2 from '../images/slides/slide2.png';
import s3 from '../images/slides/slide3.png';
import bg from '../images/bg-char.png';

// const slideLs = [
//     s1,
//     s2,
//     s3
// ]

const slideRs = [
    "Over 40+ types of products",
    "Started by 4 woman entreprenures",
    "We are powered by our ambassadors"
]

// const Slides_ = () => {

//     const [slideL, setSlideL] = useState(0);
//     const [slideR, setSlideR] = useState(0);

//     const nodeRef = useRef();
//     const nodeRef2 = useRef();

//     useEffect(() => {
//         const interval = setInterval(() => {
//             let tl = gsap.timeline({});
//             tl.to(nodeRef.current,{
//                 y: -100,
//                 x: -100,
//                 opacity: 0
//             });
//             tl.call(() => setSlideL((slideL + 1)%3));
//             tl.from(nodeRef.current,{
//                 x: 100,
//                 y: 100,
//                 opacity:0,
//                 ease: Elastic.easeInOut,
//             }, "+=0.5");
//             tl.to(nodeRef2.current,{
//                 y: -100,
//                 x: 100,
//                 opacity: 0
//             },0);
//             tl.call(() => setSlideR((slideR + 1)%3));
//             tl.from(nodeRef2.current,{
//                 x: -100,
//                 y: 100,
//                 opacity:0,
//                 ease: Elastic.easeInOut,
//             }, "-=0.5");
            
//         },5000);
//         return () => clearInterval(interval);
//     },[slideL, slideR]);

//     // GSAP
//     const slide = useRef();
//     useEffect(() => {
//         gsap.from(
//             slide.current,
//             {
//                 rotationX: 120,
//                 delay: 0.3
//             }
//         )
//     },[])
//     return (
//         <div className={Styles.main} ref={slide}>
//             <div className={Styles.window}>
//                 <img ref={nodeRef} src={slideLs[slideL]} alt={`slide${slideL}`}/>
//                 <p ref={nodeRef2}>{slideRs[slideR]}</p>
//             </div>
//         </div>
//     );
// }

class Slides extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            slide1: createRef(),
            slide2: createRef(),
            slide3: createRef(),
            current: 0
        }
    }

    componentDidMount(){
        setInterval(() => {
            this.setState({current: (this.state.current+1)%3});
        },3000);
    }

    render(){
        return(
            <div className={Styles.main} style={{
                background: `url${bg}`
            }}>
                <div className={Styles.window}>
                    <div ref={this.state.slide1}
                        style={{
                            transform: this.state.current === 0 ? `translateX(0)` : this.state.current === 1 ? `translateX(-100%)` : `translate(-200%)`
                        }}>
                        <img src={s1} alt="Over 40+ types of products"/>
                    </div>
                    <div ref={this.state.slide2}
                        style={{
                            transform: this.state.current === 0 ? `translateX(0)` : this.state.current === 1 ? `translateX(-100%)` : `translate(-200%)`
                        }}>
                        <img src={s2} alt="Started by four women entrepreneurs"/>
                    </div>
                    <div ref={this.state.slide3}
                        style={{
                            transform: this.state.current === 0 ? `translateX(0)` : this.state.current === 1 ? `translateX(-100%)` : `translate(-200%)`,
                            color: "black!important"
                        }}>
                            <img src={s3} alt="We are powered by our ambassadors"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slides;