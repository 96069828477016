import React, {useContext, useEffect, useRef} from 'react';
import * as Styles from '../styles/cookbook.module.scss';
import {LanguageContext} from '../contexts/language';
import content from '../contents/links';
import NavContext from '../contexts/navigation';
import {gsap} from 'gsap';
import bg from '../images/collage.png';

const Cookbook = () => {
    const lang = useContext(LanguageContext);
    const title = lang.language ? content.tamil.cookbook : content.english.cookbook;

    // Navigation
    const section = useRef();
    const nav = useContext(NavContext);
    useEffect(() => {
        nav?.setcookbook(section.current.offsetTop);
    },[])
    
    // GSAP
    const h = useRef();
    const vid = useRef();
    useEffect(() => {
        gsap.from(h.current,{
            scrollTrigger: {
                trigger: h.current,
                start: "top 80%",
                toggleActions: 'play none none reverse'
            },
            y:30,
            opacity: 0
        });
        gsap.from(vid.current,{
            scrollTrigger: {
                trigger: vid.current,
                start: "top 80%",
                toggleActions: 'play none none reverse'
            },
            y:30,
            opacity: 0
        });
    },[])

    return (
        <section className={Styles.main} ref={section} id="cookbook"
        style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover"
        }}>
            <h1 ref={h}>{title}</h1>
            <iframe ref={vid} width="1127" height="634" src="https://www.youtube.com/embed/FxOw5fJmNO0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </section >
    );
}

export default Cookbook;