import React, {useRef, useEffect, useContext} from 'react';
import * as Styles from '../styles/products.module.scss';
import NavContext from '../contexts/navigation';

// import blue from '../images/batter house Blue.png';
// import brown from '../images/batter house brown.png';
// import green from '../images/batter house green.png';
import bg from '../images/collage.png';
import bg_green from '../images/img-green.jpeg';
import bg_brown from '../images/img-brown.jpeg';
// import bg_blue from '../images/img-double.jpeg';

import vallarai from '../images/products/vallarai.png';
import cholam from '../images/products/cholam.jpg';
import kambu from '../images/products/kambu.png';
import kuthiraivali from '../images/products/kuthiraivali.jpg';
import moringa from '../images/products/moringa.jpg';
import mudakathan from '../images/products/mudakathan.jpg';
import thinai from '../images/products/thinai.jpg';

// External
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


const Products = () => {

    // Navigation
    const section = useRef();
    const nav = useContext(NavContext);
    useEffect(() => {
        nav?.setproducts(section.current.offsetTop);
    },[])

    // Animation
    // const p1_img = useRef();
    // const p1_div = useRef();
    // const p2_img = useRef();
    // const p2_div = useRef();
    // const p3_img = useRef();
    // const p3_div = useRef();

    // useEffect(() => {
    //     gsap.from(p1_img.current, {
    //         scrollTrigger: {
    //             trigger: p1_img.current,
    //             start: "top 80%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         x: -50,
    //         opacity: 0
    //     });
    //     gsap.from(p1_div.current, {
    //         scrollTrigger: {
    //             trigger: p1_div.current,
    //             start: "top 80%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         y: 50,
    //         opacity: 0
    //     });
    //     gsap.from(p2_img.current, {
    //         scrollTrigger: {
    //             trigger: p2_img.current,
    //             start: "top 80%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         x: -50,
    //         opacity: 0
    //     });
    //     gsap.from(p2_div.current, {
    //         scrollTrigger: {
    //             trigger: p2_div.current,
    //             start: "top 80%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         y: 50,
    //         opacity: 0
    //     });
    //     gsap.from(p3_img.current, {
    //         scrollTrigger: {
    //             trigger: p3_img.current,
    //             start: "top 78%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         x: -50,
    //         opacity: 0
    //     });
    //     gsap.from(p3_div.current, {
    //         scrollTrigger: {
    //             trigger: p3_div.current,
    //             start: "top 80%",
    //             toggleActions: 'play none none reverse'
    //         },
    //         y: 50,
    //         opacity: 0
    //     });
    // },[])

    useEffect(() => {
        const container = document.querySelector("#products");
        var nodes = [...container.childNodes].slice(1);
        nodes = [...nodes[0].childNodes, ...nodes[1].childNodes];
        gsap.from(container.childNodes[0], {
            scrollTrigger: {
                trigger: container,
                start: "top 80%",
                toggleActions: "play none none reverse"
            },
            y: 50,
            opacity: 0
        });
        nodes.map(node => {
            gsap.from(node.childNodes[0], {
                scrollTrigger: {
                    trigger: node,
                    start: "top 85%",
                    toggleActions: "play none none reverse"
                },
                x: -50,
                opacity: 0
            });
            gsap.from(node.childNodes[1], {
                scrollTrigger: {
                    trigger: node,
                    start: "top 85%",
                    toggleActions: "play none none reverse"
                },
                x: 50,
                opacity: 0
            });
            return;
        });
    },[])
    
    return(
        <section className={Styles.main} ref={section} id="products"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: `100%`
            }}>
            <div className={Styles.productCard}>
                <div>
                    <h2>Our Products</h2>
                    <p>
                    Sourced from reliable partners across India, each pack reaches your doorstep after evaluation for Quality, Nutrition Value, Health benefits and affordability. Batter House brings exclusively time tested and health proven packs that can be an elixir to life in modern times. Each pack of Batter House  has immense nutritional and health value. Chosen and processed, Batter House is packed with natural ingredients that helps in weight control/loss, contains high anti oxidant quotient, immunity boosters, offers remedies in control of blood sugar (Diabetes), reduced bad cholesterol, nature’s cure for  indigestion and constipation, soothes and cures ulcers, helps prevent cancers and cardio-vascular diseases and offers you a gluten free diet. Make Batter House your partner for life, for a Healthy, Happy and Harmonious life. Try out our packs and reap the benefits of a healthy life…
                    </p>
                </div>
            </div>
            <div style={{ backgroundImage: `url(${bg_green})` }} className={Styles.variety} id="green">
            <div className={Styles.productCard} id="#vallarai">
                <img src={vallarai} alt="Batter House Blue pouch"/>
                <div>
                <h1>Vallarai Keerai Pack (Brahmi leaves)</h1>
                <p>Vallarai Keerai, is a powerful medicinal herb that confers immense health benefits primarily for enhancing brain activity and nervous system functioning, besides mitigating joint pain in arthritis, healing stomach ulcers and decreasing stress, anxiety. Some of the time tested and proven benefits : Improves Brain Functions, Boosts Immune System, Maintains Heart Health, Relaxes Muscle Cramps, Treats Constipation, Regulates Kidney function, Fortifies bones, Combats Urinary Tract Infection (UTI), Helps Cure Anemia, Reduces Skin Inflammation, Astounding Natural Therapy For Mental Distress and Remedies for Liver Dysfunction.</p>
                </div>
            </div>
            <div className={Styles.productCard} id="#thoothuvalai">
                <div>
                <h1>Thoothuvalai  Pack</h1>
                <p>Thoothuvalai is one of the important medicinal plants called as kayakalpa herb in siddha medicine. Thoothuvalai is well known for its anti-inflammatory, anti-microbial, anti-oxidant and anti-cancerous properties. Health Benefits : Reduces respiratory problems, Improves strength, stamina, memory and mental abilities, Controls diabetes, Wonder herb for cold, cough and throat irritation, Helps avoid and fight cancer and tumors.</p>
                </div>
                <div></div>
            </div>
            <div className={Styles.productCard} id="#mudakathan">
                <img src={mudakathan} alt="Batter House Blue pouch"/>
                <div>
                <h1>Mudakathan Pack, The Marvel Herb</h1>
                <p>Ayurveda is the oldest discipline of medicine and treatment. Ancient Indians were well aware of the healing potential of herbs and remedies made out of them. The uses of these herbs, methods of using and their effects were documented in the Vedas, the sacred texts of Hindus, thousands of years ago. Mudakathan keerai or leaves has strong anti-inflammatory properties and  it an effective home remedy for rheumatoid arthritis and nervous breakdown. The plant have special gastric benefits and mild laxative properties. It also relieves menstrual cramping and dysmenorrhoea. </p>
                </div>
            </div>
            <div className={Styles.productCard} id="#moringa">
                <div>
                <h1>Moringa Pack</h1>
                <p>Moringa has been used for centuries due to its medicinal properties and health benefits. It also has antifungal, antiviral, antidepressant, and anti-inflammatory properties. Fast facts on moringa: </p><p>Protecting and nourishing skin and hair, Treating edema,  Protecting the liver, Preventing and treating cancer, Treating stomach complaints, Fighting against bacterial diseases, Making bones healthier, Treating mood disorders, Protecting the cardiovascular system, Helping wounds to heal, Treating diabetes, Treating asthma, Protecting against kidney disorders, Reducing high blood pressure, Improving eye health and Treating anaemia and sickle cell disease.</p>
                </div>
                <img src={moringa} alt="Batter House Blue pouch"/>
            </div>
            </div>
            <div style={{ backgroundImage: `url(${bg_brown})` }}  className={Styles.variety} id="brown">
            <div className={Styles.productCard} id="#kambu">
                <img src={kambu} alt="Batter House Blue pouch"/>
                <div>
                <h1>Kambu Pack</h1>
                <p>Also known as “Pearl Millet”, it is a high energy food which is packed with Iron. Kambu has 8 times Iron content higher than in Rice. This aids in improving the hemoglobin level in the blood and prevents anemia. It is also rich in fiber, protein, minerals such as magnesium, zinc, manganese, folic acid, amino acids, lecithin, potassium, B complex vitamins, and calcium. Each pack helps reduce cholesterol, which is essential for maintaining a healthy heart and prevents blood pressure. The phytonutrients helps in fighting cancer and reduce the risk of cardiac arrests. Pearl millets are naturally gluten-free loaded with proteins which minimize cardiovascular diseases and diabetes.</p>
                </div>
            </div>
            <div className={Styles.productCard} id="#thinai">
                <div>
                <h1>Thinai Pack</h1>
                <p>Also known as “Foxtail Millet”, it is one of the forgotten grains that belong to the old Tamil culture. Foxtail millets are rich in energy. It is high in dietary fibre and minerals such as copper and iron that helps to keep our body strong and boost immunity. The wonder has the capability to regulate and bring down the blood sugar level, diminishing the chances of blockage in the heart. It is exceptionally wealthy in calcium, which helps in fortifying bones. When combined with legumes, its high protein content makes it a perfect substitute for a healthy vegetarian diet. It is suggested for people of all ages as it is easily digestible.</p>
                </div>
                <img src={thinai} alt="Batter House Blue pouch"/>
            </div>
            <div className={Styles.productCard} id="#ragi">
               <div></div>
                <div>
                <h1>Ragi Pack</h1>
                <p>Also known as “Finger Millet” Ragi comprises a vast array of key nutrients and useful unsaturated fats. Ragi is a source of high-quality proteins and is organically gluten-free. Finger millet, being a fantastic source of natural calcium, strengthens bones in growing children and restores optimum bone density in older people. Ragi is the top food of choice for adults, for accelerating weight loss and managing other lifestyle diseases like diabetes and obesity. Ragi being a powerhouse of iron, serves as a boon for effectively treating anaemia. Ragi is completely devoid of cholesterol and sodium, enhances good HDL levels and averts plaque and fatty deposits in heart vessels, easing cardiac muscle function and improving heart health. Due to the immense iron and calcium content in ragi, it is ideal to stimulate milk production and balance hormonal activities in expecting women and young mothers. The comprehensive nutritional content in ragi makes it a perfect food for meeting a growing child’s ever-expanding nutrient requirements. </p>
                </div>
            </div>
            <div className={Styles.productCard} id="#kuthiraivali">
                <div>
                <h1>Kuthiraivali Pack</h1>
                <p>Also known as “Barnyard Millet”,  Kuthiraivali is a multi-purpose millet having a good source of protein, highly digestible and is an excellent source of dietary fiber with good amount of soluble and insoluble fractions. The carbohydrate content of Kuthiraivali is low and slowly digestible, which makes it a nature’s gift for the modern mankind who is engaged in sedentary activities. It also shows a high degree of retrogradation of amylase, which facilitates the formation of higher amounts of resistant starches. Hence it can be potentially recommended for the patients with cardiovascular disease and diabetes mellitus. Barnyard millet is most effective in reducing blood glucose and lipid levels. It is low in Calorie, rich in fibre, has a Low Glycaemic index, Gluten free food and a  Good source of Iron. </p>
                </div>
                <img src={kuthiraivali} alt="Batter House Blue pouch"/>
            </div>
            <div className={Styles.productCard} id="#cholam">
                <img src={cholam} alt="Batter House Blue pouch"/>
                <div>
                <h1>Cholam Pack</h1>
                <p>Also known as “New quinoa”, Its high nutrient composition makes jowar a desirable grain when it comes to good health. It is Gluten-free, Rich in Fibre, lowers risk of obesity, stroke, high blood pressure, cardiac disease, diabetes and digestive problems. It helps in weight loss and is packed with anti oxidants, Vitamins, Minerals and Micronutrients.  The whole grain helps improve digestive health and is useful in treating conditions like diarrhoea, bloating, stomach ache  and constipation. Cholam helps in Improving Heart Health and energy levels. It boosts blood circulation thereby reducing the chances of anaemia. </p>
                </div>
            </div>
            </div>
        </section>
    );
}

export default Products;